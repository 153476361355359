<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              Gas Uskuna Hujjatlari {{ $t('ACTION.UPDATE') }}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3" class="pl-0">
                <v-autocomplete
                  :items="truckList"
                  v-model="gasEquipsDetails.truck_info"
                  item-text="name"
                  item-value="id"
                  return-object
                  label="Mashina raqami"
                  :loading="isLoading"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="gasEquipsDetails.monitored"
                      label="Tekshiruvdan o'tkazilgan sana"
                      prepend-inner-icon="event"
                      readonly
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="gasEquipsDetails.monitored"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="gasEquipsDetails.expires"
                      label="Tugash sanasi"
                      prepend-inner-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="gasEquipsDetails.expires"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-text-field
                  v-model="gasEquipsDetails.reg_number"
                  label="Reg raqami"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <button
                type="button"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
              >
                Saqlash
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      menu: false,
      memu2: false,
      monitored_by: '',
      expires: '',
      monitored: '',
      reg_number: '',
      truck: ''
    }
  },
  created() {
    this.$store.dispatch('getGasEquipsDetails', this.$route.params.id)
    this.$store.dispatch('getTruckLists')
  },
  computed: {
    truckList() {
      const data = this.$store.state.requests.allTruckList
      data.forEach((x) => {
        x.name = x.reg_number_region + ' ' + x.reg_number
      })
      return data
    },
    gasEquipsDetails() {
      const data = this.$store.state.requests.gasEquipsDetails
      if (data.truck_info !== undefined) data.truck_info = data.truck_info.id
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Gas Uskuna Hujjatlari {{ $t('ACTION.UPDATE') }}" }
    ])
  },
  methods: {
    save() {
      const data = {
        truck: this.gasEquipsDetails.truck_info,
        monitored: this.gasEquipsDetails.monitored,
        reg_number: this.gasEquipsDetails.reg_number,
        expires: this.gasEquipsDetails.expires
      }
      this.$store.dispatch('updateGasEquips', {
        data: data,
        id: this.$route.params.id
      })
    }
  }
}
</script>
